import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import AthleteApplicationFormSection from './AthleteApplicationFormSection';
import { ApplicationPageActionButton } from './AthletesHero';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AthleteFormInput from './AthleteFormInput';
import { toast, ToastContainer } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

import 'react-phone-number-input/style.css';

const KLAVIYO_FORM_ID = 'X2Xb6A';
const KLAVIYO_COMPANY_ID = process.env.KLAVIYO_API_KEY || 'Srdwct';

const StyledPhoneInput = styled(PhoneInput)`
  background-color: black;
  border: 1px solid #7d7c7a;
  border-radius: 0.375rem;
  padding-inline-start: 1rem;
  outline-offset: 2px;

  .PhoneInputInput {
    background-color: black;
    color: #e3dbd3;
    border-radius: 0.375rem;
    font-family: DIN-Regular;
    font-size: 12px;
    height: 2.5rem;
    padding-inline-start: 1rem;

    &:focus-visible {
      outline: 1px solid #e3dbd3 !important;
      border-radius: 0.375rem;
    }

    &:hover {
      border-radius: 0.375rem;
      outline: 1px solid #e3dbd3 !important;
    }

    &::placeholder {
      color: #7d7c7a;
      opacity: 1;
      font-family: DIN-Regular;
    }
  }
`;

// Note - phone number is added separately because it's a custom component
const contactInfoFields = [
  { id: 'firstName', placeholder: 'First Name' },
  { id: 'lastName', placeholder: 'Last Name' },
  { id: 'email', placeholder: 'Email' },
  { id: 'socialMedia', placeholder: 'Social Media Link' },
];

const questions = [
  {
    id: 'question1',
    text: 'What HYROX races do you intend to compete at this year?',
  },
  {
    id: 'question2',
    text: 'What is your HYROX competition division?',
  },
  {
    id: 'question3',
    text: "What's your training philosophy?",
  },
  {
    id: 'question4',
    text: 'Have you used a personalized nutrition or supplement product before? If so, what company?',
  },
  {
    id: 'question5',
    text: 'If you could improve one area of your health what would your focus be?',
  },
  {
    id: 'question6',
    text: 'Are you prepared to share your MNLY journey with your audience and community?',
  },
];

type Props = {
  handleClose: () => void;
};

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  socialMedia: string;
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const schema = yup.object({
  firstName: yup
    .string()
    .required('Required')
    .max(255, 'Maximum of 255 characters'),
  lastName: yup
    .string()
    .required('Required')
    .max(255, 'Maximum of 255 characters'),
  email: yup
    .string()
    .email('Invalid Email format')
    .required('Required')
    .max(255, 'Maximum of 255 characters'),
  phone: yup
    .string()
    .matches(phoneRegExp, {
      message: 'Phone number is not valid',
      excludeEmptyString: true,
    })
    .required('Required'),
  socialMedia: yup
    .string()
    .url('Invalid URL (please include https://)')
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  question1: yup
    .string()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  question2: yup
    .string()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  question3: yup
    .string()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  question4: yup
    .string()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  question5: yup
    .string()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  question6: yup
    .string()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
});

const AthleteApplicationForm = ({ handleClose }: Props) => {
  const methods = useForm<Inputs>({ resolver: yupResolver(schema) });
  const {
    formState: { errors },
  } = methods;
  const [toastStatusIsError, setToastStatusIsError] = useState(false);

  const klaviyoRef = useRef<HTMLDivElement>(null);

  const onSubmit: SubmitHandler<Inputs> = data => {
    try {
      //invoke kavviyo action
      const firstNameInputField = klaviyoRef!.current!.querySelector(
        'input[id^=first_name_]'
      );
      const lastNameInputField = klaviyoRef!.current!.querySelector(
        'input[id^=last_name_]'
      );
      const emailInputField =
        klaviyoRef!.current!.querySelector('input[id^=email_]');
      const phoneInputField =
        klaviyoRef!.current!.querySelector('input[id^=phone_]');
      const socialMediaField = klaviyoRef!.current!.querySelector(
        'input[id^=Social_Media_]'
      );

      const question1 = klaviyoRef!.current!.querySelector(
        'input[id^=Athlete_Application_Question_1]'
      );
      const question2 = klaviyoRef!.current!.querySelector(
        'input[id^=Athlete_Application_Question_2]'
      );
      const question3 = klaviyoRef!.current!.querySelector(
        'input[id^=Athlete_Application_Question_3]'
      );
      const question4 = klaviyoRef!.current!.querySelector(
        'input[id^=Athlete_Application_Question_4]'
      );
      const question5 = klaviyoRef!.current!.querySelector(
        'input[id^=Athlete_Application_Question_5]'
      );
      const question6 = klaviyoRef!.current!.querySelector(
        'input[id^=Athlete_Application_Question_6]'
      );

      const submitBtn = document.querySelectorAll(
        `#klaviyo-form-${KLAVIYO_FORM_ID}-container  button`
      )[1]; // the phone input has a button too, so we get the second button

      // @ts-ignore
      firstNameInputField.value = data.firstName;
      // @ts-ignore
      lastNameInputField.value = data.lastName;
      // @ts-ignore
      emailInputField.value = data.email;
      // @ts-ignore
      phoneInputField.value = data.phone;

      // @ts-ignore
      socialMediaField.value = data.socialMedia;

      // @ts-ignore
      question1.value = data.question1;
      // @ts-ignore
      question2.value = data.question2;
      // @ts-ignore
      question3.value = data.question3;
      // @ts-ignore
      question4.value = data.question4;
      // @ts-ignore
      question5.value = data.question5;
      // @ts-ignore
      question6.value = data.question6;

      // @ts-ignore
      firstNameInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      lastNameInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      emailInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      phoneInputField.dispatchEvent(new Event('input'));

      // @ts-ignore
      socialMediaField.dispatchEvent(new Event('input'));

      // @ts-ignore
      question1.dispatchEvent(new Event('input'));
      // @ts-ignore
      question2.dispatchEvent(new Event('input'));

      // @ts-ignore
      question3.dispatchEvent(new Event('input'));

      // @ts-ignore
      question4.dispatchEvent(new Event('input'));

      // @ts-ignore
      question5.dispatchEvent(new Event('input'));

      // @ts-ignore
      question6.dispatchEvent(new Event('input'));

      // @ts-ignore
      submitBtn!.click();

      setToastStatusIsError(false);
      toast.success('Application received!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    } catch (err) {
      console.log(err);
      setToastStatusIsError(true);
      toast.error(
        'Error submitting application, please try again or contact support.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );
    }
    handleClose();
  };

  useEffect(() => {
    if (klaviyoRef!.current!.innerHTML === '') {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_COMPANY_ID}`
      );

      for (let i = 0; i < 10; i++) {
        const div = document.createElement('div');
        div.setAttribute('class', `klaviyo-form-${KLAVIYO_FORM_ID}`);

        klaviyoRef!.current!.appendChild(script);
        klaviyoRef!.current!.appendChild(div);
      }

      if (klaviyoRef?.current) {
        klaviyoRef.current.style.display = 'none';
      }
    }
  }, []);


  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Flex
          direction="column"
          backgroundColor="#221F1D"
          justifyContent="center"
          p="5%"
          gap="1rem"
        >
          <Flex direction="column" position="relative" justifyContent="center">
            <Flex justifyContent="center">
              <Text
                color="#E3DBD3"
                fontFamily="DIN-Bold"
                fontSize="16px"
                textAlign="center"
                width="60%"
              >
                Please complete contact details and application questions
              </Text>
              <Box
                width="15px"
                height="15px"
                position="absolute"
                top="0"
                right="0"
                border="1px solid #7D7C7A"
                opacity="50%"
                onClick={() => handleClose()}
                cursor="pointer"
                _hover={{
                  borderColor: '#ffffff',
                }}
              >
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/close-icon.png"
                  color="#7D7C7A"
                />
              </Box>
            </Flex>
          </Flex>

          <Flex flexWrap="wrap" gap="1rem">
            {contactInfoFields.map(field => (
              <AthleteFormInput
                id={field.id}
                key={field.id}
                placeholder={field.placeholder}
              />
            ))}
          </Flex>
          <FormControl isInvalid={!!methods.formState.errors['phone']}>
            <Controller
              name="phone"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <StyledPhoneInput
                  placeholder="Phone Number"
                  value={value}
                  onChange={onChange}
                  defaultCountry="US"
                  id="phone-input"
                />
              )}
            />
            <FormErrorMessage>
              {methods.formState.errors['phone'] &&
                methods.formState.errors['phone']?.message}
            </FormErrorMessage>
          </FormControl>
          {questions.map((question, index) => (
            <AthleteApplicationFormSection
              key={question.id}
              id={question.id}
              text={question.text}
            />
          ))}
          {Object.values(errors).map(error => (
            <FormErrorMessage>{error.message}</FormErrorMessage>
          ))}

          <Divider borderColor="#7D7C7A" orientation="horizontal" />
          <Flex direction="column" justifyContent="center" alignItems="center" gap="1rem">
            {Object.values(errors).length > 0 && (
              <Text color="#E53E3E">All fields are required.</Text>
            )}
            <ApplicationPageActionButton type="submit" width="25%">
              SEND FORM
            </ApplicationPageActionButton>
          </Flex>
        </Flex>
      </form>
      <div
        ref={klaviyoRef}
        id={`klaviyo-form-${KLAVIYO_FORM_ID}-container`}
      ></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: toastStatusIsError ? 'initial' : '#303525',
        }}
        theme="dark"
      />
    </FormProvider>
  );
};

export default AthleteApplicationForm;
