import { useState } from 'react';
import type { AnchorLink } from '..';
import ProductLogo from './ProductLogo';
import {
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { userLogOut } from '../../../store/actions/sessionActions';
import { quizReset } from '../../../store/actions/quizActions';
import {ManageSubscriptions} from '../../../pages/ManageSubscriptions'

interface TopMenuProps {
  links: AnchorLink[];
  name?: string;
}

const TopMenu = ({ links, name }: TopMenuProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const [isMyAccountSelected, setIsMyAccountSelected] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const { user } = useSelector(state => {
    //@ts-ignore
    return state.session;
  });

  const DesktopNavGroup1 = ({ links }: { links: AnchorLink[] }) => {
    const navigate = useNavigate();
    const linkHoverColor = '#7D7C7A';

    return (
      <Stack
        backgroundColor="#141414"
        direction={'row'}
        gap="0.5rem"
        position="relative"
        spacing={4}
        top="2.5rem"
      >
        {links.map(navItem => (
          <Box
            _hover={{
              textDecoration: 'none',
              color: linkHoverColor,
            }}
            as="a"
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize=".75rem"
            href={'javascript:void()'}
            key={navItem.label}
            onClick={() => {
              navigate(navItem.href ?? '#');
            }}
            p={2}
            px={0}
            textTransform="uppercase"
          >
            {navItem.label}
          </Box>
        ))}
      </Stack>
    );
  };
  const DesktopNavGroup2 = ({
    dispatch,
    links,
    user,
    isProductPage,
  }: {
    dispatch: any;
    isProductPage: boolean;
    links: AnchorLink[];
    user: any;
  }) => {
    const navigate = useNavigate();
    const linkHoverColor = '#7D7C7A';
    const filteredLinks = [
      ...links,
      {
        label: 'GET STARTED',
        href: '/product',
        isButton: true,
      },
    ].filter(link => !(isProductPage && link.isButton));
    return (
      <Stack
        direction={'row'}
        gap="1.0rem"
        position="relative"
        spacing={4}
        top="2.5rem"
      >
       {links
          .sort((a, b) => {
            return user.id ? a.index - b.index : b.index - a.index;
          })
          .map(navItem =>
            !navItem.isButton ? (
              <Menu isLazy>
                <MenuButton
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}
                  color="#E3DBD3"
                  fontFamily="DIN-Medium"
                  fontSize=".75rem"
                  key={navItem.label}
                  p={2}
                  px={0}
                  textTransform="uppercase"
                >
                  {navItem.label}
                </MenuButton>
                {user?.id ? (
                  <MenuList
                    fontFamily="DIN-Medium"
                    bg="#141414"
                    color="#E3DBD3"
                    borderRadius="0"
                    borderColor="#7D7C7A"
                    borderWidth="2px"
                  >
                    <MenuItem
                      py={2}
                      _active={{ bg: '#000' }}
                      _focus={{ bg: '#000' }}
                      onClick={() => {
                        navigate('/dashboard?tab=Scores');
                      }}
                    >
                      <Text>PORTAL</Text>
                    </MenuItem>
                    <MenuItem
                      py={2}
                      _active={{ bg: '#000' }}
                      _focus={{ bg: '#000' }}
                      onClick={() => {
                        navigate('/manageSubscriptions')
                      }}
                    >
                      <Text>MANAGE SUBSCRIPTION</Text>
                    </MenuItem>
                    <MenuItem
                      py={2}
                      _active={{ bg: '#000' }}
                      _focus={{ bg: '#000' }}
                      onClick={() => {
                        dispatch(userLogOut());
                        dispatch(quizReset());
                        navigate('/');
                      }}
                    >
                      <Text>LOG OUT</Text>
                    </MenuItem>
                    
                    {/* <MenuItem py={2} _focus={{ bg: '#000' }}>
                    <Link to="/login">Reset Password</Link>
                  </MenuItem> */}
                  </MenuList>
                ) : (
                  <MenuList
                    fontFamily="DIN-Medium"
                    bg="#141414"
                    color="#E3DBD3"
                    borderRadius="0"
                    borderColor="#7D7C7A"
                    borderWidth="4px"
                  >
                    <MenuItem
                      py={2}
                      _active={{ bg: '#000' }}
                      _focus={{ bg: '#000' }}
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      LOG IN
                    </MenuItem>
                    {/* <MenuItem
                    _active={{ bg: '#000', cursor: 'default' }}
                    _focus={{ bg: '#000', cursor: 'default' }}
                    onClick={() => {
                      window.location.href = shopifyURL;
                    }}
                    py={2}
                  >
                    REGISTER
                  </MenuItem> */}
                  </MenuList>
                )}
              </Menu>
            ) : (
              
              <Button
                _hover={{
                  bg: '#A0856A',
                  textDecoration: 'none',
                }}
                bg="#685848"
                borderRadius="0"
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize=".875rem"
                fontWeight={550}
                key={navItem.label}
                lineHeight="normal"
                onClick={() => {
                  navigate('/product');
                }}
                position="relative"
                px="1.25rem"
                py="1.25rem"
                textTransform="uppercase"
                top="-0.25rem"
                visibility={user.id ? 'hidden' : 'visible'}
              >
                {navItem.label}
              </Button>
            )
          )}
      </Stack>
    );
  };

  const MobileNav = ({
    dispatch,
    links,
    user,
  }: {
    dispatch: any;
    links: AnchorLink[];
    user: any;
  }) => {
    const navigate = useNavigate();

    let navItems;
    if (isMyAccountSelected) {
      navItems = (
        <Stack
          bg="#000"
          p={4}
          display={{ lg: 'none', base: 'block' }}
          textAlign="center"
          position="absolute"
          top="99px"
          left="0"
          zIndex="2"
          width="100%"
          height="80vh"
        >
          {user?.id ? (
            <>
              <Stack spacing={4} onClick={onToggle} mt={8}>
                <Box
                  _hover={{
                    textDecoration: 'none',
                  }}
                  alignItems="center"
                  as="a"
                  bg="#000"
                  href={'javascript:void()'}
                  justifyContent="space-between"
                  onClick={() => {
                    navigate('/dashboard?tab=Scores');
                  }}
                  py={4}
                >
                  <Text
                    _hover={{
                      textDecoration: 'none',
                      color: '#7D7C7A',
                    }}
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="24px"
                    fontStyle="normal"
                  >
                    PORTAL
                  </Text>
                </Box>
              </Stack>
              <Stack spacing={4} onClick={onToggle} mt={8}>
                <Box
                  _hover={{
                    textDecoration: 'none',
                  }}
                  alignItems="center"
                  as="a"
                  bg="#000"
                  href={'javascript:void()'}
                  justifyContent="space-between"
                  onClick={() => {
                    navigate('/manageSubscriptions');
                  }}
                  py={4}
                >
                  <Text
                    _hover={{
                      textDecoration: 'none',
                      color: '#7D7C7A',
                    }}
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="24px"
                    fontStyle="normal"
                  >
                    MANAGE SUBSCRIPTION
                  </Text>
                </Box>
              </Stack>
              <Stack spacing={4} onClick={onToggle} mt={8}>
                <Box
                  _hover={{
                    textDecoration: 'none',
                  }}
                  alignItems="center"
                  as="a"
                  bg="#000"
                  href={'javascript:void()'}
                  justifyContent="space-between"
                  onClick={() => {
                    dispatch(userLogOut());
                    dispatch(quizReset());
                    navigate('/');
                  }}
                  py={4}
                >
                  <Text
                    _hover={{
                      textDecoration: 'none',
                      color: '#7D7C7A',
                    }}
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="24px"
                    fontStyle="normal"
                  >
                    LOG OUT
                  </Text>
                </Box>
              </Stack>
            </>
          ) : (
            <Stack spacing={4} onClick={onToggle} mt={8}>
              <Box
                _hover={{
                  textDecoration: 'none',
                }}
                alignItems="center"
                as="a"
                bg="#000"
                href={'javascript:void()'}
                justifyContent="space-between"
                onClick={() => {
                  navigate('/login');
                }}
                py={4}
              >
                <Text
                  _hover={{
                    textDecoration: 'none',
                    color: '#7D7C7A',
                  }}
                  color="#E3DBD3"
                  fontFamily="DIN-Medium"
                  fontSize="24px"
                  fontStyle="normal"
                >
                  LOG IN
                </Text>
              </Box>
            </Stack>
          )}
        </Stack>
      );
    } else {
      navItems = (
        <Stack
          bg="#000"
          p={4}
          display={{ lg: 'none', base: 'block' }}
          textAlign="center"
          position="absolute"
          top="99px"
          left="0"
          zIndex="2"
          width="100%"
          height="80vh"
        >
          {links
            .filter(link => link.group === 1)
            .map(navItem => (
              <MobileNavItem key={navItem.label} {...navItem} />
            ))}
          {links
            .filter(link => link.group === 2 && !link.isButton)
            .map(navItem => (
              <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
      );
    }
    return navItems;
  };
  const MobileNavItem = ({ label, href }: AnchorLink) => {
    const navigate = useNavigate();

    let navItem;
    if (label === 'MY ACCOUNT') {
      navItem = (
        <Stack spacing={4} mt={8}>
          <Box
            _hover={{
              textDecoration: 'none',
            }}
            alignItems="center"
            bg="#000"
            justifyContent="space-between"
            onClick={() => {
              setIsMyAccountSelected(true);
            }}
            py={4}
          >
            <Text
              _hover={{
                textDecoration: 'none',
                color: '#7D7C7A',
              }}
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="24px"
              fontStyle="normal"
            >
              {label}
            </Text>
          </Box>
        </Stack>
      );
    } else {
      navItem = (
        <Stack spacing={4} mt={8}>
          <Box
            _hover={{
              textDecoration: 'none',
            }}
            alignItems="center"
            bg="#000"
            justifyContent="space-between"
            onClick={() => {
              onToggle();
              navigate(`${href}` ?? '#');
            }}
            py={4}
          >
            <Text
              _hover={{
                textDecoration: 'none',
                color: '#7D7C7A',
              }}
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="24px"
              fontStyle="normal"
            >
              {label}
            </Text>
          </Box>
        </Stack>
      );
    }

    return navItem;
  };

  return (
    <>
      {/*/!* Laptop - Laptop L - 4K *!/*/}
      <Box
        bg="#141414"
        display={{ base: 'none', lg: 'flex' }}
        padding="0px 34px"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          bg={{ '2xl': '#141414', xl: '#141414', lg: '#141414' }}
          pl={{ base: '.5em', lg: '0em' }}
          textAlign={{ base: 'left', lg: 'right' }}
        >
          <Link to="/">
            <ProductLogo
              boxSize="104px"
              name={name}
              position="relative"
              top="-0.05rem"
            />
          </Link>
          <Box bg="#141414" height="99px" pl="6.1rem">
            <DesktopNavGroup1 links={links.filter(link => link.group === 1)} />
          </Box>
        </Box>

        <Box bg="#141414" height="99px" pl="0rem" justifySelf="end">
          <DesktopNavGroup2
            dispatch={dispatch}
            isProductPage={['/product', '/dashboard'].includes(
              location.pathname.toLowerCase()
            )}
            links={links.filter(link => link.group === 2)}
            user={user}
          />
        </Box>
      </Box>
      {/* Mobile S - Mobile M - Mobile L - Tablet */}
      <Flex
        bg="#141414"
        display={{ base: 'flex', '2xl': 'none', xl: 'none', lg: 'none' }}
        maxW="100%"
        width="100vw"
      >
        {' '}
        <Link to="/">
          <ProductLogo
            boxSize="104px"
            ml="2rem"
            position="relative"
            top="-0.05rem"
          />
        </Link>
        <IconButton
          aria-label={'Toggle Navigation'}
          color="#E3DBD3"
          display="inline"
          icon={
            isOpen ? (
              <CloseIcon w={5} h={5} position="relative" top={2} />
            ) : (
              <HamburgerIcon w={9} h={9} position="relative" top={2} />
            )
          }
          mr={{
            '2xl': '2rem',
            xl: '2rem',
            lg: '2rem',
            base: '1rem',
          }}
          onClick={() => {
            setIsMyAccountSelected(false);
            onToggle();
          }}
          textAlign="right"
          variant="link"
          width="100%"
        />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav links={links} user={user} dispatch={dispatch} />
      </Collapse>
    </>
  );
};

function mapStateToProps(state: { session: any }) {
  return {
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {})(TopMenu);
