import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  id: string;
  placeholder: string;


}
const AthleteFormInput = ({ id, placeholder }: Props) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <FormControl isInvalid={!!errors[id]}>
      <Input
        _placeholder={{ color: '#7D7C7A' }}
        _focus={{
          borderWidth: '1px',
        }}
        focusBorderColor="#E3DBD3"
        borderColor="#7D7C7A"
        id={id}
        bg="black"
        fontFamily="DIN-Regular"
        fontSize="12px"
        size="md"
        color="#E3DBD3"
        placeholder={placeholder}
        {...register(id)}
      />
      <FormErrorMessage>
        {errors[id] && errors[id]?.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default AthleteFormInput;
