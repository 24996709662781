import React, { useState } from 'react';
import {
  Divider,
  Box,
  Flex,
  Text,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import AthleteFormTextArea from './AthleteFormTextArea';
import { useFormContext } from 'react-hook-form';

type Props = {
  id: string;
  text: string;
};
const AthleteApplicationFormSection = ({ id, text }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Divider borderColor="#7D7C7A" orientation="horizontal" mb="10px" />
      <Flex direction="column" pl="10px" pr="10px" gap="10px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            color="#E3DBD3"
            fontFamily="DIN-Regular"
            size="12px"
            width="95%"
          >
            {text}
          </Text>
          <Box
            width="15px"
            height="15px"
            onClick={() => setOpen(!open)}
            cursor="pointer"
          >
            {open ? (
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src={`/static/icons/x-icon.png`}
                  color="#7D7C7A"
                />
              </Box>
            ) : (
              <img
                alt="MNLY Precision Men's Health"
                src={`/static/icons/plus-icon.png`}
                color="#7D7C7A"
              />
            )}
          </Box>
        </Flex>
        {open && (
          <FormControl isInvalid={!!errors[id]}>
            <AthleteFormTextArea id={id} {...register(id)} />
            <FormErrorMessage>
              {errors[id] && errors[id]?.message}
            </FormErrorMessage>
          </FormControl>
        )}
      </Flex>
    </Box>
  );
};

export default AthleteApplicationFormSection;
