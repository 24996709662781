import { forwardRef, Textarea, TextareaProps } from '@chakra-ui/react';
import React from 'react';

const AthleteFormTextArea = forwardRef<TextareaProps, 'input'>((props, ref) => (
  <Textarea
    bg="black"
    fontFamily="DIN-Regular"
    fontSize="12px"
    size="md"
    _focus={{
      borderWidth: '1px',
    }}
    focusBorderColor="#E3DBD3"
    color="#E3DBD3"
    ref={ref}
    {...props}
  />
));

export default AthleteFormTextArea;
